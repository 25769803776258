const socialLinkHelper = {
  twitter(url: string) {
    return url.indexOf('.com') > -1 ? url : `https://www.twitter.com/${url}`;
  },
  facebook(url: string) {
    return url.indexOf('.com') > -1 ? url : `https://www.facebook.com/${url}`;
  },
  instagram(url: string) {
    return url.indexOf('.com') > -1 ? url : `https://www.instagram.com/${url.replace('@', '')}`;
  },
  snapchat(url: string) {
    return `https://www.snapchat.com/add/${url}`;
  },
  voxer(url: string) {
    return `https://web.voxer.com/u/${url}`;
  },
  contact(url: string) {
    return this.urlCheck(url);
  },
  urlCheck(url: string) {
    const newUrl = url.replace(/^http(s?):\/\//i, '');
    return url ? `https://${newUrl}` : '';
  },
};

export default socialLinkHelper;
