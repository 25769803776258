import { Anchor, IcFluentMegaphone24Regular } from '@flipgrid/flipkit';
import { useMatches } from '@remix-run/react';
import { isEmpty } from 'lodash-es';
import { useTranslation } from 'react-i18next';

import SocialButtons from './SocialButtons';
import ProfilePicture, { links as profilePictureStyles } from '../Utility/components/ProfilePicture';
import adminExternalLinks from '~/constants/adminExternalLinks';
import sanitize from '~/helper/sanitize';
import profileCardStyles from '~/styles/components/Profile/ProfileCard.css';

import type { Profile, User } from 'types';

export const links = () => [...profilePictureStyles(), { rel: 'stylesheet', href: profileCardStyles }];

type Props = {
  discovery?: boolean;
  profile?: Profile;
  user: User;
} & (
{
  partner: true;
  redirectURL: string;
} |
{
  partner?: false;
  redirectURL?: never;
});


const ProfileCard = ({ discovery, partner, profile, redirectURL, user }: Props) => {
  const { t } = useTranslation();
  const [routeData] = useMatches();
  const env = routeData.data.env;
  const { id, first_name: firstName, has_public_topic_templates: publicTemplates, domains, groups } = user;
  let imageUrl = partner ? user.icon : user.image_url;
  let fullName = user.full_name;
  let socialLinks = user.social_links;
  let { bio } = user;

  if (profile) {
    imageUrl = profile.icon;
    fullName = profile.display_name;
    bio = profile.bio;
    socialLinks = profile.profile_links;
  }

  return (
    <div className="profileCard">
      <ProfilePicture className="profileCard__defaultPic" image_url={imageUrl} id={id} />
      <h3 className="fk-m0">{fullName}</h3>
      {!partner && (publicTemplates || discovery) && id &&
      <Anchor
        variant="button"
        theme="secondary"
        className="profileCard__discoLibraryLink"
        to={adminExternalLinks.DISCOVERY_DETAILS_URL(env.ADMIN_URL, id, firstName)}
        data-testid="profileCard__link__disco"
        icon={<IcFluentMegaphone24Regular />}>

          {t('profileCard.myTopics')}
        </Anchor>}

      {partner &&
      <Anchor
        variant="button"
        data-testid="profileCard__link__viewTopics"
        theme="secondary"
        className="profileCard__discoLibraryLink"
        to={redirectURL}
        icon={<IcFluentMegaphone24Regular />}>

          {t('profileCard.viewTopics')}
        </Anchor>}

      {bio &&
      <div
        className="fk-editorInner profileCard__bio"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: sanitize.strict(bio) }} />}


      {groups && !isEmpty(groups) &&
      <div className="profileCard__grades">
          <b>{t('profileCard.myCommunity')}</b>
          {/* eslint-disable-next-line i18next/no-literal-string */}
          <div>{Object.values(groups).join(', ')}</div>
        </div>}

      {domains && !isEmpty(domains) &&
      <div className="profileCard__subjects">
          <b>{t('profileCard.mySubjects')}</b>
          {/* eslint-disable-next-line i18next/no-literal-string */}
          <div>{Object.values(domains).join(', ')}</div>
        </div>}

      {socialLinks && !isEmpty(socialLinks) &&
      <div className="profileCard__socialLinks">
          <SocialButtons value={socialLinks.twitter} social="Twitter" size="44" />
          <SocialButtons value={socialLinks.facebook} social="Facebook" size="44" />
          <SocialButtons value={socialLinks.instagram} social="Instagram" size="44" />
          <SocialButtons value={socialLinks.snapchat} social="Snapchat" size="44" />
          <SocialButtons value={socialLinks.voxer} social="Voxer" size="44" />
          <SocialButtons value={socialLinks.youtube} social="YouTube" size="44" />
          <SocialButtons value={socialLinks.skype} social="Skype" size="44" />
          <SocialButtons value={socialLinks.linkedin} social="LinkedIn" size="44" />
        </div>}

    </div>);

};

export default ProfileCard;