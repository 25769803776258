import { Anchor, IcFluentLink24Regular } from '@flipgrid/flipkit';
import { useTranslation } from 'react-i18next';

import socialLinkHelper from '~/helper/socialLinkHelper';
import socialButtonsStyles from '~/styles/components/Profile/SocialButtons.css';
import FacebookIcon from '~/svg/social/FacebookIcon';
import InstagramIcon from '~/svg/social/InstagramIcon';
import LinkedinIcon from '~/svg/social/LinkedinIcon';
import SkypeIcon from '~/svg/social/SkypeIcon';
import SnapchatIcon from '~/svg/social/SnapchatIcon';
import TwitterIcon from '~/svg/social/TwitterIcon';
import VoxerIcon from '~/svg/social/VoxerIcon';
import YoutubeIcon from '~/svg/social/YoutubeIcon';

export const links = () => [{ rel: 'stylesheet', href: socialButtonsStyles }];

type Props = {
  size: 'custom' | '44';
  social: 'Facebook' | 'Instagram' | 'LinkedIn' | 'Skype' | 'Snapchat' | 'Twitter' | 'Voxer' | 'YouTube' | 'Contact';
  value?: string;
};

const SocialButtons = ({ size = 'custom', social, value }: Props) => {
  const { t } = useTranslation();

  const socialEnum = {
    Facebook: {
      color: 'fk-facebookBlue',
      icon: <FacebookIcon />,
      ariaLabel: t('socialButtons.goToFacebook')
    },
    Instagram: {
      color: 'fk-instagramGradient',
      icon: <InstagramIcon size="22" />,
      ariaLabel: t('socialButtons.goToInstagram')
    },
    LinkedIn: {
      color: 'fk-linkedIn',
      icon: <LinkedinIcon className="socialButtons__linkedIn" backgroundFill="transparent" />,
      ariaLabel: t('socialButtons.goToLinkedIn')
    },
    Skype: {
      color: 'fk-skype',
      icon: <SkypeIcon size="18" />,
      ariaLabel: t('socialButtons.goToSkype')
    },
    Snapchat: {
      color: 'fk-snapchatYellow',
      icon: <SnapchatIcon size="25" />,
      ariaLabel: t('socialButtons.goToSnapchat')
    },
    Twitter: {
      color: 'fk-twitterBlue',
      icon: <TwitterIcon />,
      ariaLabel: t('socialButtons.goToTwitter')
    },
    Voxer: {
      color: 'fk-voxerOrange',
      icon: <VoxerIcon height="24px" width={undefined} />,
      ariaLabel: t('socialButtons.goToVoxer')
    },
    YouTube: {
      color: 'fk-youtube',
      icon: <YoutubeIcon size="20" />,
      ariaLabel: t('socialButtons.goToYouTube')
    },
    Contact: {
      color: '',
      icon: <IcFluentLink24Regular />,
      ariaLabel: t('socialButtons.goToContact')
    }
  };

  const getUrl = () => {
    const valueToUse = value || '';
    if (social === 'Facebook') return socialLinkHelper.facebook(valueToUse);
    if (social === 'Instagram') return socialLinkHelper.instagram(valueToUse);
    if (social === 'Snapchat') return socialLinkHelper.snapchat(valueToUse);
    if (social === 'Twitter') return socialLinkHelper.twitter(valueToUse);
    if (social === 'Voxer') return socialLinkHelper.voxer(valueToUse);
    if (social === 'Contact') return socialLinkHelper.contact(valueToUse);
    return socialLinkHelper.urlCheck(valueToUse);
  };

  if (!value) return null;

  return (
    <Anchor
      to={getUrl()}
      className={`socialButtons__social ${socialEnum[social].color} -${size}`}
      aria-label={socialEnum[social].ariaLabel}
      data-testid={`socialButtons__link__${social.toLowerCase()}`}
      icon={socialEnum[social].icon}
      size={size !== 'custom' ? size : undefined}
      theme="transparent"
      variant="circle"
      newTab />);


};

export default SocialButtons;