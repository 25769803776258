import { memo } from 'react';

type Props = {
  className?: string;
  fill?: string;
  height?: string;
  width?: string;
};

const VoxerIcon = ({ className, fill = '#ffffff', height = '33px', width = '27px' }: Props) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 27 33"
    className={className}
    xmlSpace="preserve"
  >
    <path
      fill={fill}
      d="M27,27.4c0-2.3-0.1-5.2-0.4-8.3c-0.1-0.8-1-7-2.9-12.6c-0.7-2-1-3.8-1.9-5.6c-0.6-1.2-3.8-1.1-5,0
    c-0.4,0.4-0.6,0.8-0.8,1.1c-0.1,0.3-0.2,0.7-0.1,1.1l1.2,4.5c0,0-2.9-0.3-5.5-0.2C9.1,7.6-2.4,8.2,0.5,17.3
    c1.5,4.7,2.1,9.2,2.4,12.2c3,2.2,6.8,3.5,10.9,3.5C18.9,33,23.6,30.8,27,27.4z M19.1,13c0.7-0.3,1.4,0.1,1.7,0.8
    c0.3,0.7,0,1.5-0.7,1.8c-0.7,0.3-1.4-0.1-1.7-0.8C18.2,14,18.5,13.2,19.1,13z M5.1,17c0-0.8,0.6-1.5,1.4-1.5c0.8,0,1.4,0.7,1.4,1.5
    c0,0.8-0.6,1.5-1.4,1.5C5.7,18.5,5.1,17.8,5.1,17z M22.1,20.2c-1.6,1.9-4,3.4-6.4,4c-2.7,0.6-5.9,0.3-8.5-0.7
    c-0.6-0.2-0.3-1.2,0.3-0.9c2.4,1,5.4,1.2,8,0.7c2.3-0.5,4.5-1.9,6-3.7C21.8,19.1,22.5,19.8,22.1,20.2z"
    />
  </svg>
);

export default memo(VoxerIcon);
