import { Button, Link, Modal } from '@flipgrid/flipkit';
import { useTranslation } from 'react-i18next';

import routes from '../../../constants/routes';

import type { OnRequestCloseType } from '@flipgrid/flipkit';

type Props = {
  closeTopicTemplateModal: () => void;
  onRequestClose: OnRequestCloseType;
};

const AddTopicConfirmationModal = ({ closeTopicTemplateModal, onRequestClose }: Props) => {
  const { t } = useTranslation();

  return (
    <Modal onClose={onRequestClose}>
      <h1 className="fk-modalHeader">{t('addTopicConfirmationModal.topicAdded')}</h1>
      <p className="fk-modalBody fk-m0">{t('addTopicConfirmationModal.addedToGroups')}</p>

      <Modal.Actions className="mt2">
        <Button theme="tertiary" data-testid="addTopicConfirmationModal__button__close" onClick={onRequestClose}>
          {t('common.close')}
        </Button>
        <Link
          to={routes.GROUPS}
          data-testid="addTopicConfirmationModal__link__goToTopics"
          onClick={() => {
            closeTopicTemplateModal();
            onRequestClose();
          }}
          variant="button"
        >
          {t('addTopicConfirmationModal.goToGroups')}
        </Link>
      </Modal.Actions>
    </Modal>
  );
};

export default AddTopicConfirmationModal;
