import { Button, Popover, useGeneratedPrefixedId } from '@flipgrid/flipkit';
import { useState, useRef, useCallback } from 'react';

import ProfileCard, { links as profileCardStyles } from './ProfileCard';
import ProfilePicture, { links as profilePictureStyles } from '../Utility/components/ProfilePicture';
import { formatName } from '~/helper/formatting';
import profilePopupStyles from '~/styles/components/Profile/ProfilePopup.css';

import type { Profile, User } from 'types';

export const links = () => [
...profileCardStyles(),
...profilePictureStyles(),
{ rel: 'stylesheet', href: profilePopupStyles }];


type Props = {
  'aria-describedby'?: string;
  discovery?: boolean;
  profile?: Profile;
  truncate?: boolean;
  user: User;
};

const ProfilePopup = ({
  'aria-describedby': ariaDescribedBy = '',
  discovery,
  profile,
  truncate = true,
  user
}: Props) => {
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const buttonRef = useRef(null);
  const popoverId = useGeneratedPrefixedId('profilePopup-');

  const toggleIsPopoverVisible = useCallback(() => {
    setIsPopoverVisible((prev) => !prev);
  }, [setIsPopoverVisible]);

  const profileName = profile ? profile.display_name : formatName(user, true);

  return (
    <>
      <Button
        aria-label={profileName}
        aria-describedby={`${isPopoverVisible ? popoverId : ''} ${ariaDescribedBy}`}
        variant="text"
        className="profilePopup__button"
        data-testid="profilePopup__button__openCallout"
        actionInnerClassName="profilePopup__icon"
        ref={buttonRef}
        icon={<ProfilePicture image_url={profile?.icon || user.image_url} id={user.id} />}
        onClick={toggleIsPopoverVisible}>

        <span className={truncate ? 'profilePopup__truncate' : ''}>{profileName}</span>
      </Button>

      <Popover
        id={popoverId}
        className="profilePopup"
        isVisible={isPopoverVisible}
        targetRef={buttonRef}
        onDismiss={toggleIsPopoverVisible}>

        <ProfileCard user={user} profile={profile} discovery={discovery} />
      </Popover>
    </>);

};

export default ProfilePopup;