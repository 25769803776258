import { memo } from 'react';

type Props = {
  className?: string;
  size?: string;
};

const SkypeIcon = ({ className, size = '24' }: Props) => (
  <svg
    version="1.1"
    xmlns="https://www.w3.org/2000/svg"
    xmlnsXlink="https://www.w3.org/1999/xlink"
    viewBox="0 0 16 16"
    xmlSpace="preserve"
    className={className}
    width={size}
    height={size}
  >
    <path
      fill="#FFFFFF"
      d="M4.4,0c0.8,0,1.5,0.2,2.2,0.6c0.5-0.1,0.9-0.1,1.3-0.1c0.7,0,1.3,0.1,2,0.3
        s1.2,0.4,1.8,0.8c0.6,0.3,1.1,0.7,1.5,1.2s0.9,1,1.2,1.5c0.3,0.6,0.6,1.2,0.8,1.8s0.3,1.3,0.3,2c0,0.4,0,0.9-0.1,1.3
        c0.4,0.7,0.6,1.4,0.6,2.2c0,0.6-0.1,1.2-0.3,1.7s-0.6,1-1,1.4s-0.9,
        0.7-1.4,1S12.2,16,11.6,16c-0.8,0-1.5-0.2-2.2-0.6
        c-0.5,0.1-0.9,0.1-1.3,0.1c-0.7,0-1.3-0.1-2-0.3s-1.2-0.4-1.8-0.8c-0.6-0.3-1.1-0.7-1.5-1.2s-0.9-1-1.2-1.5
        C1.3,11.1,1,10.5,0.8,9.9s-0.3-1.3-0.3-2c0-0.4,0-0.9,0.1-1.3C0.2,
        5.9,0,5.2,0,4.4c0-0.6,0.1-1.2,0.3-1.7s0.6-1,1-1.4
        s0.9-0.7,1.4-1S3.8,0,4.4,0z"
    />
    <path
      fill="#00AFF0"
      d="M7.8,3.4c0.4,0,0.9,0.1,1.4,0.2c0.6,0.2,1,0.4,1.4,0.7s0.6,0.7,0.6,1.2c0,0.2-0.1,0.4-0.3,0.6
      c-0.1,0.1-0.2,0.1-0.3,0.1s-0.2,0.1-0.3,0.1s-0.2,0-0.3-0.1S9.8,
      6.1,9.7,5.9C9.6,5.8,9.5,5.7,9.4,5.6C9.1,5.3,8.8,5.2,8.5,5
      C8.3,4.9,8.1,4.9,7.8,4.9c-0.4,0-0.7,0.1-1,0.2C6.7,5.2,6.5,
      5.3,6.5,5.4S6.4,5.7,6.4,5.9s0.1,0.4,0.2,0.5S7,6.6,7.2,6.7
      c0.3,0.1,0.6,0.2,0.9,0.2c0.8,0.2,1.5,0.3,2,0.5c0.7,0.2,1.2,
      0.7,1.5,1.2c0.2,0.3,0.2,0.7,0.2,1.1c0,0.5-0.1,0.9-0.3,1.3
      s-0.5,0.7-0.9,0.9c-0.3,0.2-0.7,0.4-1.2,0.5s-0.9,0.2-1.3,0.2c-0.7,0-1.3-0.1-1.9-0.3c-0.7-0.2-1.2-0.6-1.5-1.1
      c-0.1-0.2-0.2-0.3-0.2-0.5s-0.1-0.3-0.1-0.4c0-0.2,0.1-0.4,
      0.3-0.6C4.8,9.6,4.9,9.6,5,9.5s0.2-0.1,0.3-0.1c0.2,0,0.3,0,0.4,0.1
      s0.2,0.2,0.4,0.3l0.3,0.4c0.3,0.3,0.6,0.6,1,0.7C7.6,11,7.9,11,8.2,11c0.2,0,0.4,0,0.6-0.1s0.4-0.1,0.5-0.2
      c0.2-0.1,0.3-0.2,0.4-0.4S9.9,10,9.9,9.8S9.8,9.4,9.7,9.3S9.3,9,9.1,
      8.9C8.8,8.8,8.6,8.7,8.2,8.7c-0.8-0.2-1.5-0.3-2-0.5
      C5.5,7.9,5,7.5,4.7,7C4.5,6.7,4.5,6.4,4.5,5.9S4.6,5,4.8,4.7s0.5-0.6,
      0.8-0.9c0.3-0.2,0.7-0.4,1.1-0.4S7.3,3.4,7.8,3.4z"
    />
  </svg>
);

export default memo(SkypeIcon);
