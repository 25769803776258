import {
  Banner,
  Button,
  Dropdown,
  Add16Solid,
  IcFluentChevronLeft24Regular,
  IcFluentChevronRight24Regular,
  IcFluentPeople24Regular,
  Link,
  Loader,
  Modal,
  ModalConsumer,
  Tag,
  TopicIntegration,
  TopicResourceType } from
'@flipgrid/flipkit';
import { useFetcher, useMatches } from '@remix-run/react';
import { Fragment, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import AddTemplateToGroupsModal, { links as addTemplateToGroupsModalStyles } from './AddTemplateToGroupsModal';
import AddTemplateToNewGroupModal from './AddTemplateToNewGroupModal';
import AddTopicConfirmationModal from './AddTopicConfirmationModal';
import ProfilePopup, { links as profilePopupStyles } from '../../Profile/ProfilePopup';
import ImmersiveReaderButton from '~/components/ImmersiveReader/ImmersiveReaderButton';
import { immersiveReaderIsOpen } from '~/components/ImmersiveReader/ImmersiveReaderHelperFunctions.client';
import TopicResource, { links as topicResourceStyles } from '~/components/Topics/TopicResource';
import adminExternalLinks from '~/constants/adminExternalLinks';
import resourceRoutes from '~/constants/resourceRoutes';
import Telemetry from '~/constants/telemetry';
import { getEngagementTimeFormat } from '~/helper/dates';
import { telemetryAttributes } from '~/helper/helper';
import sanitize from '~/helper/sanitize';
import topicTemplateModalStyles from '~/styles/components/Modals/Discovery/TopicTemplateModal.css';

import type { OnRequestCloseType } from '@flipgrid/flipkit';
import type { CollectionList, TopicTemplate } from 'types';

export const links = () => [
...addTemplateToGroupsModalStyles(),
...profilePopupStyles(),
...topicResourceStyles(),
{ rel: 'stylesheet', href: topicTemplateModalStyles }];


type Props = {
  onRequestClose: OnRequestCloseType;
  topicTemplateId: string;
  traversable: boolean;
};

const TopicTemplateModal = ({ onRequestClose, topicTemplateId, traversable }: Props) => {
  const { t } = useTranslation();
  const fetcher = useFetcher();
  const [collectionList, setCollectionList] = useState<CollectionList>();
  const [topicTemplate, setTopicTemplate] = useState<TopicTemplate>();
  const [hasLoaded, setHasLoaded] = useState(false);
  const [showNewGroupModal, setShowNewGroupModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const matches = useMatches();
  const env = matches[0]?.data?.env;

  useEffect(() => {
    const qs = new URLSearchParams();
    qs.append('topicTemplateId', topicTemplateId);
    fetcher.load(resourceRoutes.topicTemplates + `?${qs}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topicTemplateId]);

  useEffect(() => {
    if (fetcher.type === 'done' && fetcher.data?.data) {
      setTopicTemplate(fetcher.data.topicTemplate.data);
      setCollectionList(fetcher.data.data);
      setHasLoaded(true);
    }
  }, [fetcher]);

  const onGroupCreation = () => {
    setShowNewGroupModal(false);
    onRequestClose();
  };

  const wrapperClassNames = () => {
    return `topicTemplateModal__modalWrapper${traversable ? ' -traversable' : ''}`;
  };

  const creatorId = 'topic-TemplateModal-creator';

  const showPrevButton = () => {
    if (!traversable) return false;
    /*
    // False when the topic template is the 1st in the list
    // True when the topic template is any item other than the 1st in the list
    if (topicTemplate && topicTemplate.length > 1 && topicIndex > 0) {
      if (isCollectionList)
        return (
          topicTemplate[0] && topicTemplate[0].item && topicTemplate[0].item.id && topicTemplate[0].item.id.toString() !== topicTemplate.id
        );
      return topicTemplate[0] && topicTemplate[0].id && topicTemplate[0].id.toString() !== topicTemplate.id;
    }
    */
    return false;
  };

  const showNextButton = () => {
    if (!traversable) return false;
    // Show the next button if there are more than 1 topic templates in the list
    /*
    if (topicTemplate && topicTemplate.length > 1 && topicIndex > -1) {
      return true;
    }
    */
    return false;
  };

  const pagingButtons = (mobile = false) => {
    return (
      <ModalConsumer>
        {() =>
        <Fragment>
            {showPrevButton() &&
          <Button
            className={'topicTemplateModal__previous' + (mobile ? '' : ' -desktop')}
            data-testid="topicTemplateModal__button__previous"
            aria-label={t('common.previous')}
            icon={<IcFluentChevronLeft24Regular className="draggerPrev__icon" />}
            onClick={() => {


              // TODO set up previous button
              // previous(showModal);
            }} size="36" variant="circle" />}


            {showNextButton() &&
          <Button
            className={'topicTemplateModal__next' + (mobile ? '' : ' -desktop')}
            data-testid="topicTemplateModal__button__next"
            aria-label={t('common.next')}
            icon={<IcFluentChevronRight24Regular className="draggerNext__icon" />}
            onClick={() => {


              // TODO set up next button
              // next(showModal);
            }} size="36" variant="circle" />}


          </Fragment>}

      </ModalConsumer>);

  };

  const getBannerText = () => {
    if (!topicTemplate) return;
    if (topicTemplate.status === 'denied')
    return t('topicTemplateModal.deniedTopic', { message: topicTemplate.moderation_message });
    if (topicTemplate.status === 'needs_updates')
    return (
      <Trans i18nKey="topicTemplateModal.requiresEdits">
          Your Topic requires edits before being approved to the Discovery Library.{' '}
          <Link
          to={adminExternalLinks.DISCOVERY_ID_URL(env.ADMIN_URL, topicTemplate?.id)}
          data-testid="topicTemplateModal__link__editTopic"
          onClick={() => onRequestClose()}>

            Edit Topic
          </Link>
        </Trans>);

    return t('topicTemplateModal.pendingTopic');
  };

  const getBannerTheme = () => {
    if (!topicTemplate) return 'blue';
    if (topicTemplate.status === 'denied') return 'red';
    if (topicTemplate.status === 'needs_updates') return 'yellow';

    return 'blue';
  };

  const getBannerClass = () => {
    const classList = ['topicTemplateModal__banner'];
    if (traversable) classList.push('-traversable');

    return classList.join(' ');
  };

  const getMobileBannerText = () => {
    if (!topicTemplate) return;
    if (topicTemplate.status === 'denied') return t('common.denied');
    if (topicTemplate.status === 'needs_updates') return t('common.requiresEdits');
    return t('common.pending');
  };

  return (
    <Modal
      closeClassName="topicTemplateModal__closeButton"
      className={'topicTemplateModal__modal' + (topicTemplate?.status === 'approved' ? ' ' + wrapperClassNames() : '')}
      dangerouslyBypassFocusLock={immersiveReaderIsOpen()}
      wrapperClassName={wrapperClassNames()}
      onClose={onRequestClose}
      animation={false}
      topComponent={
      hasLoaded && topicTemplate ?
      <Fragment>
            {
          // Desktop
        }
            {topicTemplate?.status !== 'approved' ?
        <Banner theme={getBannerTheme()} className={getBannerClass()} fitContent>
                <p className="fk-m0">{getBannerText()}</p>
              </Banner> :
        null}
            {
          // Mobile
        }
            <div className={'topicTemplateModal__mobileTopWrapper' + (traversable ? ' -traversable' : '')}>
              <div>{pagingButtons(true)}</div>
              <div>
                {topicTemplate?.status !== 'approved' &&
            <Banner
              theme={getBannerTheme()}
              className="topicTemplateModal__bannerMobile"
              size="medium"
              fitContent>

                    <p className="fk-m0">{getMobileBannerText()}</p>
                  </Banner>}

                <Modal.CloseButton theme="white" onClick={onRequestClose} />
              </div>
            </div>
          </Fragment> :
      null}>


      {hasLoaded && topicTemplate ?
      <div className="topicTemplateModal__wrapper">
          <div className="topicTemplateModal__left">
            <div className="topicTemplateModal__leftTop">
              <TopicResource topic={topicTemplate} type="TopicTemplate" />
              <div className="topicTemplateModal__leftMiddle">
                <h1 className="fk-h3">{topicTemplate.title}</h1>
                <div
                className={
                'topicTemplateModal__text' + (
                topicTemplate && topicTemplate.focus && topicTemplate.focus.type !== TopicResourceType.Other ?
                ' -focus' :
                '')}

                dangerouslySetInnerHTML={{
                  __html: topicTemplate.text
                }} />

              </div>
            </div>
            <div className="topicTemplateModal__leftFooter">
              <TopicIntegration
            // @ts-ignore
            topic={topicTemplate}
            showImmersiveReader
            immersiveReaderButton={ImmersiveReaderButton} />

            </div>
          </div>

          <div className="topicTemplateModal__right">
            <div className="topicTemplateModal__rightTop">
              <div className="topicTemplateModal__rightHeader">
                <h2 className="fk-h4 fk-m0">{t('topicTemplateModal.topicDetails')}</h2>
              </div>
              <hr className="topicTemplateModal__hr" />
              {topicTemplate.user &&
            <>
                  <b id={creatorId} className="mb05 fk-fontSize__14">
                    {t('common.creator')}
                  </b>
                  <ProfilePopup
                aria-describedby={creatorId}
                profile={topicTemplate.profile}
                user={topicTemplate.user}
                discovery
                truncate={false} />

                </>}

              {/* TODO: Fix these to use real values from template */}
              <b className="mb05 fk-fontSize__14">{t('common.community')}</b>
              <div>
                <Tag as="div" className="mb1">
                  {t('common.other')}
                </Tag>
              </div>

              <b className="mb05 fk-fontSize__14">{t('common.subject')}</b>
              <div>
                <Tag as="div" className="mb1">
                  {t('common.other')}
                </Tag>
              </div>

              <div className="topicTemplateModal__stats fk-fontSize__14">
                <div>
                  <b>{t('topicTemplateModal.savedTo')}</b>
                  <p>
                    {t('topicTemplateModal.numCollections', {
                    count: collectionList?.ids?.length || 0
                  })}
                  </p>
                </div>
                <div>
                  <b>{t('common.engagement')}</b>
                  <p>{getEngagementTimeFormat(topicTemplate.total_engagement_length)}</p>
                </div>
                <div>
                  <b>{t('common.used')}</b>
                  <p>{topicTemplate.topic_count}</p>
                </div>
              </div>

              {topicTemplate.integration_notes &&
            <Fragment>
                  <h2 className="fk-h4">{t('topicTemplateModal.tipsForUsage')}</h2>
                  <div
                className={
                'topicTemplateModal__integrationNotes' + (
                topicTemplate && topicTemplate.focus && topicTemplate.focus.type !== TopicResourceType.Other ?
                ' -focus' :
                '')}

                dangerouslySetInnerHTML={{
                  __html: sanitize.strict(topicTemplate.integration_notes)
                }} />

                </Fragment>}

            </div>
            <Modal.Actions className="mt2">
              <Dropdown
              button={
              <Dropdown.Button
                className="topicTemplateModal__useTopic"
                data-testid="topicTemplateModal__button__dropdown"
                {...telemetryAttributes(Telemetry.TopicTemplateModalAddTopic)}
                size="44"
                icon={<Add16Solid />}>

                    {t('shared.addTopic')}
                  </Dropdown.Button>}>


                <Dropdown.Item
                data-testid="topicTemplateModal__button__addToYourGroups"
                {...telemetryAttributes(Telemetry.TopicTemplateModalAdd)}
                onSelect={() => {
                  setShowAddModal(true);
                }}
                icon={<IcFluentPeople24Regular />}>

                  {t('topicTemplateModal.addToYourGroups')}
                </Dropdown.Item>

                <Dropdown.Item
                data-testid="topicTemplateModal__button__createANewGroup"
                {...telemetryAttributes(Telemetry.TopicTemplateModalAddToNewGroup)}
                onSelect={() => {
                  setShowNewGroupModal(true);
                }}
                icon={<Add16Solid />}>

                  {t('shared.createANewGroup')}
                </Dropdown.Item>
              </Dropdown>
            </Modal.Actions>
          </div>
        </div> :

      <div className="topicTemplateModal__loading">
          <Loader container />
        </div>}

      {topicTemplate &&
      <>
          {showAddModal &&
        <AddTemplateToGroupsModal
          onRequestClose={() => setShowAddModal(false)}
          openConfirmationModal={() => {
            setShowAddModal(false);
            setShowConfirmationModal(true);
          }}
          topicTemplate={topicTemplate} />}


          {showNewGroupModal &&
        <AddTemplateToNewGroupModal
          closeTopicTemplateModal={onRequestClose}
          onRequestClose={() => setShowNewGroupModal(false)}
          topicTemplate={topicTemplate}
          handleGroupCreation={onGroupCreation} />}


          {showConfirmationModal &&
        <AddTopicConfirmationModal
          closeTopicTemplateModal={onRequestClose}
          onRequestClose={() => setShowConfirmationModal(false)} />}


        </>}

    </Modal>);

};

export default TopicTemplateModal;