import { memo } from 'react';

type Props = {
  size?: string;
};

const InstagramIcon = ({ size = '32' }: Props) => (
  <svg
    version="1.1"
    xmlns="https://www.w3.org/2000/svg"
    xmlnsXlink="https://www.w3.org/1999/xlink"
    viewBox="0 0 32 32"
    xmlSpace="preserve"
    width={size}
    height={size}
  >
    <path
      fill="#FFFFFF"
      d="M16,5.75c3.34,0,3.73,0.01,5.05,0.07c1.22,0.06,1.88,0.26,2.32,0.43c0.58,0.23,1,0.5,1.44,0.94
      c0.44,0.44,0.71,0.85,0.94,1.44c0.17,0.44,0.37,1.1,0.43,2.32c0.06,1.32,0.07,1.71,0.07,5.05s-0.01,3.73-0.07,5.05
      c-0.06,1.22-0.26,1.88-0.43,2.32c-0.23,0.58-0.5,1-0.94,1.44c-0.44,
      0.44-0.85,0.71-1.44,0.94c-0.44,0.17-1.1,0.37-2.32,0.43
      c-1.32,0.06-1.71,0.07-5.05,0.07s-3.73-0.01-5.05-0.07c-1.22-0.06-1.88-0.26-2.32-0.43c-0.58-0.23-1-0.5-1.44-0.94
      c-0.44-0.44-0.71-0.85-0.94-1.44c-0.17-0.44-0.37-1.1-0.43-2.32C5.76,19.73,5.75,19.34,5.75,16s0.01-3.73,0.07-5.05
      c0.06-1.22,0.26-1.88,0.43-2.32c0.23-0.58,0.5-1,0.94-1.44c0.44-0.44,
      0.85-0.71,1.44-0.94c0.44-0.17,1.1-0.37,2.32-0.43
      C12.27,5.76,12.66,5.75,16,5.75 M16,3.5c-3.39,0-3.82,0.01-5.15,
      0.08C9.52,3.64,8.61,3.85,7.81,4.16C6.99,4.48,6.29,4.9,5.6,5.6
      C4.9,6.29,4.48,6.99,4.16,7.81c-0.31,0.8-0.52,1.7-0.58,3.03C3.51,12.18,3.5,12.61,3.5,16c0,3.39,0.01,3.82,0.08,5.15
      c0.06,1.33,0.27,2.24,0.58,3.03c0.32,0.82,0.75,1.52,1.44,
      2.21c0.69,0.69,1.39,1.12,2.21,1.44c0.8,0.31,1.7,0.52,3.03,0.58
      c1.33,0.06,1.76,0.08,5.15,0.08s3.82-0.01,5.15-0.08c1.33-0.06,2.24-0.27,3.03-0.58c0.82-0.32,1.52-0.75,2.21-1.44
      c0.69-0.69,1.12-1.39,1.44-2.21c0.31-0.8,0.52-1.7,0.58-3.03c0.06-1.33,0.08-1.76,0.08-5.15s-0.01-3.82-0.08-5.15
      c-0.06-1.33-0.27-2.24-0.58-3.03C27.52,6.99,27.1,6.29,26.4,
      5.6c-0.69-0.69-1.39-1.12-2.21-1.44c-0.8-0.31-1.7-0.52-3.03-0.58
      C19.82,3.51,19.39,3.5,16,3.5L16,3.5z"
    />
    <path
      fill="#FFFFFF"
      d="M16,9.58c-3.55,0-6.42,2.87-6.42,6.42s2.87,6.42,6.42,6.42s6.42-2.87,6.42-6.42S19.55,9.58,16,9.58z
       M16,20.17c-2.3,0-4.17-1.87-4.17-4.17s1.87-4.17,4.17-4.17c2.3,0,4.17,1.87,4.17,4.17S18.3,20.17,16,20.17z"
    />
    <circle fill="#FFFFFF" cx="22.67" cy="9.33" r="1.5" />
  </svg>
);

export default memo(InstagramIcon);
