import { memo } from 'react';

type Props = {
  className?: string;
  size?: number | string;
};

const YoutubeIcon = ({ className, size = '28' }: Props) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    className={className}
    width={size}
    height={size}
    viewBox="0 0 28 28"
    enableBackground="new 0 0 28 28"
    xmlSpace="preserve"
  >
    <path
      fill="#FFFFFF"
      d="M27.4,7.2C27.1,6,26.1,5,24.9,4.7C22.8,4.1,14,4.1,14,4.1s-8.8,0-10.9,0.6C1.9,5,0.9,6,0.6,7.2
        C0,9.4,0,14,0,14s0,4.6,0.6,6.8C0.9,22,1.9,23,3.1,23.3C5.2,23.9,14,23.9,14,
        23.9s8.8,0,10.9-0.6c1.2-0.3,2.2-1.3,2.5-2.5
        C28,18.6,28,14,28,14S28,9.4,27.4,7.2z M11.1,18.2V9.8l7.3,4.2L11.1,18.2z"
    />
  </svg>
);

export default memo(YoutubeIcon);
