import { Banner, Button, ButtonSubmit, Loader, Modal } from '@flipgrid/flipkit';
import { useFetcher } from '@remix-run/react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import MultiSelect, { links as multiSelectStyles } from '~/components/Utility/components/MultiSelect';
import resourceRoutes from '~/constants/resourceRoutes';
import telemetry from '~/constants/telemetry';
import { telemetryAttributes } from '~/helper/helper';

import type { OnRequestCloseType } from '@flipgrid/flipkit';
import type { LinksFunction } from '@remix-run/node';
import type { MultiValue } from 'react-select';
import type { ApiResponse, Group, MultiSelectOption, TopicTemplate } from 'types';

export const links: LinksFunction = () => [...multiSelectStyles()];

type Props = {
  onRequestClose: OnRequestCloseType;
  openConfirmationModal: () => void;
  topicTemplate: TopicTemplate;
};

const AddTemplateToGroupsModal = ({ onRequestClose, openConfirmationModal, topicTemplate }: Props) => {
  const { t } = useTranslation();
  const submissionFetcher = useFetcher<ApiResponse<Group[]>>();
  const groupsFetcher = useFetcher<ApiResponse<Group[]>>();
  const [selectedGroupIds, setSelectedGroupIds] = useState<MultiValue<MultiSelectOption>>([]);
  const [hasInputError, setHasInputError] = useState(false);
  const isGroupsLoading =
    groupsFetcher.state === 'loading' || (groupsFetcher.state === 'idle' && groupsFetcher.type !== 'done');

  useEffect(() => {
    if (selectedGroupIds.length > 20) {
      setHasInputError(true);
    }
  }, [selectedGroupIds]);

  useEffect(() => {
    const shouldLoadGroups = groupsFetcher.state === 'idle' && !groupsFetcher.data;

    if (shouldLoadGroups) {
      const params = {
        per_page: '250',
        standalone: 'true',
        load_all: 'true',
      };
      const formattedParams = new URLSearchParams(params);
      groupsFetcher.load(`${resourceRoutes.myGroups}?${formattedParams.toString()}`);
    }
  }, [groupsFetcher]);

  useEffect(() => {
    const isSuccess = submissionFetcher.type === 'done';
    if (!isSuccess) return;

    const isError = selectedGroupIds.length === 0 || selectedGroupIds.length > 20;
    const hasResponse = Object.keys(submissionFetcher.data?.data ?? {}).length > 0;
    if (hasResponse) {
      openConfirmationModal();
    } else if (isError) {
      setHasInputError(true);
    }
  }, [submissionFetcher.data?.data, submissionFetcher.type, openConfirmationModal, selectedGroupIds.length]);

  const getFormattedGroups = useCallback((): MultiSelectOption[] => {
    const groupList = groupsFetcher.data?.data;
    if (!groupList) return [];
    return groupList.map(group => ({ value: group.id, label: group.name }));
  }, [groupsFetcher.data?.data]);

  return (
    <Modal show onClose={onRequestClose}>
      <submissionFetcher.Form
        action={resourceRoutes.manageTopics}
        method="post"
        onSubmit={() => setHasInputError(false)}
      >
        <h1 className="fk-modalHeader">{t('shared.addToGroup')}</h1>
        <p className="fk-modalBody">
          <Trans i18nKey="shared.addTopicToDiscussion">
            Add <b>{{ title: (topicTemplate && topicTemplate.title) ?? '' }}</b> to your discussion.
          </Trans>
        </p>
        {isGroupsLoading ? (
          <Loader container />
        ) : (
          <MultiSelect
            aria-required="true"
            data-testid="addTemplateToGroupsModal__multiSelect__groups"
            displayLabel={t('shared.selectGroups')}
            error={hasInputError ? t('shared.selectDestinationGroup') : undefined}
            onChange={selectedGroup => setSelectedGroupIds(selectedGroup)}
            options={getFormattedGroups()}
            placeholder={t('shared.selectOrTypeToSearch')}
          />
        )}
        {selectedGroupIds.length >= 20 && (
          <Banner theme="red" className="mt1" size="small" fitContent>
            {t('addTemplateToGroupsModal.max20Groups')}
          </Banner>
        )}
        <Modal.Actions className="mt2">
          <Button theme="tertiary" data-testid="addTemplateToGroupsModal__button__cancel" onClick={onRequestClose}>
            {t('common.cancel')}
          </Button>
          <ButtonSubmit
            {...telemetryAttributes(telemetry.AddTopicTemplateToGroupsModalSaveToGroup)}
            data-testid="addTemplateToGroupsModal__button__submit"
            disabled={selectedGroupIds.length === 0}
            loading={submissionFetcher.state !== 'idle'}
            name="_action"
            value="addTemplateToExistingGroup"
          >
            {t('shared.saveToGroup', { count: selectedGroupIds.length })}
          </ButtonSubmit>
        </Modal.Actions>
        <input
          type="hidden"
          name="selectedGroupIds"
          value={JSON.stringify(selectedGroupIds.map(option => option.value.toString()))}
        />
        <input type="hidden" name="fieldsToParse" value={JSON.stringify(['selectedGroupIds'])} />
        <input type="hidden" name="topicTemplateId" value={topicTemplate.id} />
      </submissionFetcher.Form>
    </Modal>
  );
};

export default AddTemplateToGroupsModal;
