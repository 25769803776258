import { memo } from 'react';

type Props = {
  backgroundFill?: string;
  className?: string;
};

const LinkedinIcon = ({ backgroundFill = '#007BB6', className }: Props) => {
  return (
    <svg
      version="1.1"
      xmlns="https://www.w3.org/2000/svg"
      xmlnsXlink="https://www.w3.org/1999/xlink"
      viewBox="0 0 144 144"
      className={className}
      width={30}
      height={38}
    >
      <path
        fill={backgroundFill}
        d="M133.3,0H10.6C4.8,0,0,4.6,0,10.4v123.2c0,5.7,4.8,10.4,10.6,10.4h122.7c5.9,0,10.7-4.7,10.7-10.4V10.4
      C144,4.6,139.2,0,133.3,0z"
      />
      <path
        fill="#FFFFFF"
        d="M21.3,54h21.4v68.7H21.3V54z M32,19.8c6.8,0,12.4,5.5,12.4,12.4c0,6.8-5.5,12.4-12.4,12.4
      c-6.8,0-12.4-5.5-12.4-12.4C19.6,25.4,25.2,19.8,32,19.8"
      />
      <path
        fill="#FFFFFF"
        d="M56.1,54h20.5v9.4h0.3c2.9-5.4,9.8-11.1,20.2-11.1c21.6,0,25.6,14.2,25.6,32.7v37.7h-21.3V89.3
      c0-8-0.1-18.2-11.1-18.2c-11.1,0-12.8,8.7-12.8,17.6v34H56.1V54z"
      />
    </svg>
  );
};

export default memo(LinkedinIcon);
