import { memo } from 'react';

type Props = {
  size?: string;
};

const SnapchatIcon = ({ size = '32' }: Props) => (
  <svg
    version="1.1"
    xmlns="https://www.w3.org/2000/svg"
    xmlnsXlink="https://www.w3.org/1999/xlink"
    viewBox="24.299 25.935 54 50"
    width={size}
    height={size}
  >
    <path
      fill="#FFFFFF"
      stroke="#000000"
      strokeMiterlimit="10"
      d="M76.5,64.243c0,0.224,0,0.446,0,0.67 c-1.424,1.418-3.281,1.848-5.15,2.082c-1.322,0.166-1.824,0.818-2.037,
      2.034c-0.293,1.66-0.438,1.688-2.057,1.365 c-2.955-0.59-5.713-0.295-8.297,1.493c-5.053,3.495-9.929,
      3.455-15.014,0.051c-1.174-0.785-2.357-1.559-3.812-1.753 c-1.642-0.219-3.259-0.149-4.873,0.25c-1.265,
      0.312-1.516,0.134-1.805-1.069c-0.061-0.252-0.154-0.498-0.188-0.754
      c-0.125-0.938-0.596-1.33-1.589-1.503c-1.986-0.349-4.08-0.561-5.58-2.196c0-0.224,0-0.446,0-0.67 c0.237-0.099,
      0.468-0.229,0.715-0.287c4.792-1.096,7.937-4.207,10.168-8.385c1.292-2.417,0.719-3.773-1.873-4.734
      c-0.904-0.333-1.847-0.577-2.663-1.115c-0.656-0.433-1.387-0.866-0.996-1.847c0.292-0.734,1.448-1.229,2.29-1.001
      c0.428,0.116,0.828,0.338,1.255,0.463c0.749,0.221,1.495,0.43,2.299,0.191c0.639-0.191,0.879-0.502,0.819-1.208
      c-0.161-1.895-0.282-3.794-0.328-5.694c-0.096-3.886,1.146-7.246,4.117-9.854c4.504-3.955,12.087-4.564,17.218-1.288
      c3.402,2.171,5.539,5.178,5.832,9.376c0.168,2.398-0.158,4.768-0.119,7.153c0.021,1.279,0.518,1.823,1.781,1.568
      c0.723-0.145,1.406-0.469,2.113-0.698c0.959-0.31,2.373,0.182,2.596,0.963c0.307,1.073-0.465,1.563-1.252,1.96
      c-0.594,0.298-1.229,0.522-1.855,0.751c-0.734,0.266-1.486,0.502-2.111,0.985c-0.846,0.65-1.271,1.492-0.861,2.547
      c1.789,4.594,4.729,8.06,9.57,9.613C75.375,63.884,75.986,63.905,76.5,64.243z"
    />
  </svg>
);

export default memo(SnapchatIcon);
