import { Banner, Button, ButtonSubmit, Modal } from '@flipgrid/flipkit';
import { useFetcher } from '@remix-run/react';
import { useContext, useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import Input from '~/components/FkWrappers/Input';
import resourceRoutes from '~/constants/resourceRoutes';
import telemetry from '~/constants/telemetry';
import globalContext from '~/contexts/globalContext';
import { telemetryAttributes } from '~/helper/helper';

import type { OnRequestCloseType } from '@flipgrid/flipkit';
import type { ApiResponse, Topic, TopicTemplate } from 'types';

type Props = {
  closeTopicTemplateModal: () => void;
  handleGroupCreation: () => void;
  onRequestClose: OnRequestCloseType;
  topicTemplate: TopicTemplate;
};

const AddTemplateToNewGroupModal = ({
  closeTopicTemplateModal,
  handleGroupCreation,
  onRequestClose,
  topicTemplate,
}: Props) => {
  const { t } = useTranslation();
  const { announceLiveMessage } = useContext(globalContext);
  const fetcher = useFetcher<ApiResponse<Topic>>();
  const [hasApiFailure, setHasApiFailure] = useState(false);
  const [groupName, setGroupName] = useState('');

  useEffect(() => {
    if (fetcher.type === 'done') {
      const isSuccess = !fetcher.data;
      if (isSuccess) {
        announceLiveMessage(
          t('addTemplateToNewGroupModal.topicHasBeenAddedToYourNewGroup', { topicTitle: topicTemplate.title }),
        );
        handleGroupCreation();
        return;
      }
      setHasApiFailure(true);
    }
  }, [announceLiveMessage, closeTopicTemplateModal, fetcher, handleGroupCreation, t, topicTemplate.title]);

  return (
    <Modal
      show
      onClose={onRequestClose}
      topComponent={
        hasApiFailure && (
          <Banner className="mb2" theme="redSecondaryWithBg">
            {t('addTemplateToNewGroupModal.problemCreatingGroup')}
          </Banner>
        )
      }
    >
      <fetcher.Form action={resourceRoutes.manageTopics} method="post" onSubmit={() => setHasApiFailure(false)}>
        <h1 className="fk-modalHeader">{t('addTemplateToNewGroupModal.createANewGroupForThisTopic')}</h1>
        <p className="fk-modalBody">
          <Trans i18nKey="shared.addTopicToDiscussion">
            Add <b>{{ title: (topicTemplate && topicTemplate.title) ?? '' }}</b> to your discussion.
          </Trans>
        </p>
        <Input
          aria-required="true"
          autoFocus
          counter={groupName.length}
          label={t('shared.createANewGroup')}
          maxLength={45}
          onChange={e => setGroupName(e.target.value)}
          name="groupName"
          placeholder={t('shared.nameYourGroup')}
          wrapperClassName="mt1"
        />
        <Modal.Actions className="mt2">
          <Button theme="tertiary" data-testid="addTemplateToNewGroupModal__button__cancel" onClick={onRequestClose}>
            {t('common.cancel')}
          </Button>
          <ButtonSubmit
            data-testid="addTemplateToNewGroupModal__button__submit"
            disabled={groupName.length === 0}
            loading={fetcher.state === 'submitting' || fetcher.type === 'actionRedirect'}
            name="_action"
            value="addTemplateToNewGroup"
            {...telemetryAttributes(telemetry.AddTopicTemplateModalPostToNewGroup)}
          >
            {t('common.post')}
          </ButtonSubmit>
        </Modal.Actions>
        <input type="hidden" name="topicTemplateId" value={topicTemplate.id} />
      </fetcher.Form>
    </Modal>
  );
};

export default AddTemplateToNewGroupModal;
